import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setCurrency } from "@/redux/currencySlice";
import Image from "next/image";
import logo from "@/assets/homepage/HAMS.webp";
import Link from "next/link";
import "./footer.css";
import bkash from "@/assets/homepage/bkashh.png";
import bankTransfer from "@/assets/homepage/bank-transfer.png";
import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
} from "react-icons/md";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const [language, setLanguage] = useState("en");
  const [isDropdownOpenL, setIsDropdownOpenL] = useState(false);
  const { t, i18n } = useTranslation();

  const changeLanguage = (lang) => {
    setLanguage(lang);
    i18n.changeLanguage(lang);
  };
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const currency = useSelector((state) => state.currency.currency);
  const dispatch = useDispatch();

  const handleCurrencyChange = (e) => {
    dispatch(setCurrency(e.target.value));
  };

  return (
    <div className="pt-6 flex flex-col px-0 md:px-0">
      {/* <Newsletter /> */}

      <footer className="footer">
        <div className="mx-auto max-w-screen-xl space-y-8 px-4 pt-10 sm:px-6 lg:space-y-10 lg:px-8">
          <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
            <div>
              <div className="flex items-center font-semibold gap-3">
                <div className="size-[95px] flex items-center">
                  <Image src={logo} alt="" height={500} width={500}></Image>
                </div>
                <p className=" text-[#282828] text-xl">HAMS Fly Limited</p>
              </div>
              <div className="space-y-2 text-[14px] text-[#444444] mt-2">
                <p>
                  HAMS Fly is totally changing the game in global exploration
                  with its ground-breaking technology.
                </p>
              </div>
              <div className="space-y-2 text-[13px]  text-[#444444] mt-7">
                <p>
                  P.O: House 12, Road 12, Sector 04, <br /> Uttara, Dhaka -1230,
                  Bangladesh.
                  <br />
                  Registerd : KC Tower, Plot 13, Sonargaw jonopath,
                  <br />
                  Sector 13, Utttara,Dhaka-1230, Bangladesh.
                </p>
                <p>
                  Call: 08802-48957730, <br />
                  Hotline & Whatsapp:08801407020590, 01407020597
                </p>
                <p>E-mail: info@hamsfly.com</p>
              </div>
              <ul className="mt-7 flex gap-4 items-center">
                <li>
                  <Link
                    href="https://x.com/fly_hams85343?t=vCpGsdGuHhk2i_UGnwsidg&s=09"
                    target="_blank"
                  >
                    <div className="size-10 rounded-full flex items-center justify-center text-[#191D23] border border-[#d1d1d1] hover:bg-[#009D4D] hover:border-[#009D4D] border:[#E1E1E1] hover:text-white transition-all duration-200 ">
                      <FaTwitter size={18} />
                    </div>
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://www.facebook.com/hamsfly.om.bd"
                    target="_blank"
                  >
                    <div className="size-10 rounded-full flex items-center justify-center text-[#191D23] border border-[#d1d1d1] hover:bg-[#009D4D] hover:border-[#009D4D] border:[#E1E1E1] hover:text-white transition-all duration-200 ">
                      <FaFacebookF size={18} />
                    </div>
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://www.instagram.com/hamsfly1?igsh=MW91cWNwM3RnY2RnOQ=="
                    target="_blank"
                  >
                    <div className="size-10 rounded-full flex items-center justify-center text-[#191D23] border border-[#d1d1d1] hover:bg-[#009D4D] hover:border-[#009D4D] border:[#E1E1E1] hover:text-white transition-all duration-200 ">
                      <FaInstagram size={21} />
                    </div>
                  </Link>
                </li>
              </ul>
            </div>

            <div className="flex justify-between flex-col md:flex-row flex-wrap grid-cols-1 gap-8 sm:grid-cols-2 lg:col-span-2 lg:grid-cols-4">
              <div>
                <p className="font-semibold text-[#009D4D]">Company</p>

                <ul className="mt-6 space-y-4 text-sm">
                  <li>
                    <Link
                      href="/flights"
                      className="text-gray-700 hover:underline"
                    >
                      {/* {t("flight")} */}
                      Flight
                    </Link>
                  </li>
                </ul>
                <ul className="mt-4 space-y-4 text-sm">
                  <li>
                    <Link
                      href="https://partner.hamsfly.com/"
                      className="text-gray-700 hover:underline"
                      target="_blank"
                    >
                      Partner
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="/serviceGuarantee"
                      className="text-gray-700 hover:underline"
                      target="_blank"
                    >
                      Service Guarantee
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="/trust"
                      className="text-gray-700 hover:underline"
                      target="_blank"
                    >
                      Trust & Safety
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="/refund"
                      className="text-gray-700 hover:underline"
                      target="_blank"
                    >
                      Refund
                    </Link>
                  </li>
                </ul>
              </div>

              <div>
                <p className="font-semibold text-[#009D4D]">About Us</p>

                <ul className="mt-6 space-y-4 text-sm">
                  <li>
                    <Link
                      href="/about"
                      target="_blank"
                      className="text-gray-700 hover:underline"
                    >
                      About
                    </Link>
                  </li>

                  <li>
                    <Link
                      href="/career"
                      target="_blank"
                      className="text-gray-700 hover:underline"
                    >
                      Careers
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="/feedback"
                      target="_blank"
                      className="text-gray-700 hover:underline"
                    >
                      Feedback
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="/terms-Condition"
                      target="_blank"
                      className="text-gray-700 hover:underline"
                    >
                      Terms and Condition
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="/privacyPolicy"
                      target="_blank"
                      className="text-gray-700 hover:underline"
                    >
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="/faq"
                      target="_blank"
                      className="text-gray-700 hover:underline"
                    >
                      FAQ
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="/Blogs"
                      target="_blank"
                      className="text-gray-700 hover:underline"
                    >
                      Blog
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="/contact"
                      target="_blank"
                      className="text-gray-700 hover:underline"
                    >
                      Contact US
                    </Link>
                  </li>
                </ul>
              </div>

              <div>
                <p className="font-semibold text-[#009D4D] text-2xl w-full">
                  Payment Method
                </p>

                <ul className="mt-6 space-y-4 text-sm">
                  <li className="flex gap-3 items-center">
                    <div className="w-10">
                      <Image src={bkash} alt="" height={500} width={500} />
                    </div>
                    <div className="w-10">
                      <Image
                        src={bankTransfer}
                        alt=""
                        height={500}
                        width={500}
                      />
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div>
            <div className="relative flex items-center justify-center gap-5 mb-2">
              <div className="relative">
                <select
                  value={currency}
                  onChange={handleCurrencyChange}
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                  className="border rounded-md px-10 py-2 appearance-none"
                >
                  <option value="USD">USD</option>
                  <option value="BDT">BDT</option>
                </select>
                <div className="absolute inset-y-0 right-0 flex items-center px-5 pointer-events-none">
                  {isDropdownOpen ? (
                    <MdOutlineKeyboardArrowUp size={22} />
                  ) : (
                    <MdOutlineKeyboardArrowDown size={22} />
                  )}
                </div>
              </div>
              <div className="relative">
                <select
                  value={language}
                  onChange={(e) => changeLanguage(e.target.value)}
                  onClick={() => setIsDropdownOpenL(!isDropdownOpenL)}
                  className="border rounded-md px-10 py-2 appearance-none"
                >
                  <option value="" disabled>
                    Language
                  </option>
                  <option value="en">English</option>
                  <option value="bn">বাংলা</option>
                </select>
                <div className="absolute inset-y-0 right-0 flex items-center px-5 pointer-events-none">
                  {isDropdownOpenL ? (
                    <MdOutlineKeyboardArrowUp size={22} />
                  ) : (
                    <MdOutlineKeyboardArrowDown size={22} />
                  )}
                </div>
              </div>
            </div>

            <div className="flex items-center justify-center gap-5">
              <div className="w-20 overflow-hidden">
                <Image
                  src={"https://i.ibb.co.com/tCs7tn8/IATA-webp.webp"}
                  alt="IATA LOGO"
                  height={1000}
                  width={1000}
                  className="w-full"
                />
              </div>
              <div className="w-20 overflow-hidden">
                <Image
                  src={"https://i.ibb.co.com/1KycNKh/Sabre-logo.webp"}
                  alt="SABRE logo"
                  height={1000}
                  width={1000}
                  className="w-full"
                />
              </div>
            </div>
            <p className="text-sm text-center text-gray-500 border-t py-5 ">
              &copy; Copyright 2024, All Rights Reserved
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
